<template>
  <v-container>
    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-card :loading="asignacion.isLoading" class="px-4 pb-4">
          <v-card-title
            class="d-flex align-center px-0 py-0 pt-4"
            style="gap: 16px"
          >
            <NavButton />
            <h4 class="text-uppercase" style="flex-grow: 2">Detalle</h4>
            <template v-if="asignacion.data">
              <v-btn
                v-if="esJefeDeSeguimiento && departamento.data"
                color="primary"
                class="flex-grow-1"
                x-large
                :disabled="asignacion.isLoading || seguimientoRevisado"
                @click.stop="abrirModalAsignacion"
              >
                Asignar
              </v-btn>
              <CambioEstado
                v-if="asignacion.data"
                :id-seguimiento="idSeguimientoProceso"
                :estado-actual="estadoSeguimiento"
                @on-cambio-estado="manejarCambioEstado"
                @on-ocultamiento-seguimiento="manejarOcultamientoSeguimiento"
              />
            </template>
          </v-card-title>

          <RetryDataLoading
            class="mt-4"
            :loadable="asignacion"
            :retry-handler="reintentarCargaAsignacion"
          >
            <v-skeleton-loader
              v-if="asignacion.isLoading"
              class="mt-4"
              boilerplate
              type="article, article, article"
            />

            <div v-if="procesoCompra && !asignacion.isLoading">
              <h5 class="text-h6 font-weight-bold mt-4">
                <v-icon>mdi-clipboard-list-outline</v-icon>
                Proceso
              </h5>
              <v-divider class="mt-1" />
              <dl class="mt-2 mb-4">
                <dt class="font-weight-bold">Código:</dt>
                <dd>{{ procesoCompra.codigo_proceso }}</dd>

                <dt class="font-weight-bold mt-2">Nombre:</dt>
                <dd>{{ procesoCompra.nombre_proceso }}</dd>

                <dt class="font-weight-bold mt-2">Método:</dt>
                <dd>
                  {{ procesoCompra.FormaContratacion.nombre }}
                  v{{ procesoCompra.version }}
                </dd>

                <dt class="font-weight-bold mt-2">Institución:</dt>
                <dd>{{ procesoCompra.Institucion.nombre }}</dd>

                <dt class="font-weight-bold mt-2">Fecha inicio:</dt>
                <dd>
                  {{ formatearFecha(procesoCompra.fecha_inicio_proceso) }}
                </dd>

                <dt class="font-weight-bold mt-2">Fecha fin:</dt>
                <dd>{{ formatearFecha(procesoCompra.fecha_contratacion) }}</dd>

                <dt class="font-weight-bold mt-2">Monto:</dt>
                <dd>
                  ${{
                    Intl.NumberFormat("en-US", {
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 8,
                    }).format(
                      procesoCompra.monto_total ?? procesoCompra.suma_mercancia
                    )
                  }}
                </dd>

                <dt class="font-weight-bold mt-2">Etapa:</dt>
                <dd>
                  <v-chip
                    label
                    :color="obtenerColorEtapa(procesoCompra)"
                    class="white--text mt-1"
                  >
                    {{ obtenerNombreEtapa(procesoCompra) }}
                  </v-chip>
                </dd>
              </dl>

              <v-alert
                v-if="
                  procesoCompra.EstadoProceso.id === 4 &&
                  procesoCompra.id_tipo_proceso === 2
                "
                class="mt-4 mb-0"
                type="info"
                dense
              >
                Este proceso está en construcción, en procesos con reserva.
              </v-alert>

              <h5 class="text-h6 font-weight-bold mt-4">
                <v-icon>mdi-magnify</v-icon>
                Seguimiento
              </h5>
              <v-divider class="mt-1" />
              <dl class="mt-2">
                <dt class="font-weight-bold">Fecha de asignación:</dt>
                <dd>{{ formatearFechaHora(asignacion.data.created_at) }}</dd>

                <dt class="font-weight-bold mt-2">Departamento:</dt>
                <dd>
                  {{ asignacion.data.CtlDepartamentoSeguimientoProceso.nombre }}
                </dd>

                <dt class="font-weight-bold mt-2">
                  <span class="d-flex flex-wrap align-center" style="gap: 8px">
                    <p class="mb-0">Responsable:</p>
                    <v-switch
                      v-if="asignacion.data.us_res && esJefeDeSeguimiento"
                      v-model="visibleParaResponsable"
                      color="secondary"
                      class="mt-0 pt-0"
                      hide-details
                      small
                      @change="
                        confirmarCambioVisibilidadIndividual('responsable')
                      "
                    />
                  </span>
                </dt>
                <dd>
                  {{ formatearPersona(asignacion.data.us_res) }}
                </dd>

                <dt class="font-weight-bold mt-2">
                  <span class="d-flex flex-wrap align-center" style="gap: 8px">
                    <p class="mb-0">Asignado:</p>
                    <v-switch
                      v-if="asignacion.data.us_as && esJefeDeSeguimiento"
                      v-model="visibleParaAsignado"
                      color="secondary"
                      class="mt-0 pt-0"
                      hide-details
                      small
                      @change="
                        confirmarCambioVisibilidadIndividual('asignado_a')
                      "
                    />
                  </span>
                </dt>
                <dd>{{ formatearPersona(asignacion.data.us_as) }}</dd>

                                <dt class="font-weight-bold mt-2">Estado observatorio:</dt>
                                <dd>
                                    <v-chip
                                        label
                                        class="mt-1"
                                        :color="`${procesoCompra.seg_p_obs?.es_spo.color_fondo} !important`"
                                        :text-color="`${procesoCompra.seg_p_obs?.es_spo.color_texto} !important`"
                                    >
                                        {{ procesoCompra.seg_p_obs?.es_spo.nombre ?? 'Sin revisar' }}
                                    </v-chip>
                                </dd>
              </dl>
            </div>
          </RetryDataLoading>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card :loading="historial.isLoading" class="px-4 pb-4">
          <v-card-title
            class="d-flex align-center px-0 py-0 pt-4"
            style="gap: 16px"
          >
            <v-icon color="primary" large>mdi-history</v-icon>
            <h4 class="text-uppercase flex-grow-1">Historial</h4>
            <v-btn
              v-if="asignacion.data"
              @click.stop="abrirModalCreacionRegistro"
              :loading="catalogoTipoRegistros.isLoading"
              :disabled="seguimientoRevisado || seguimientoOculto"
              :color="esJefeDeSeguimiento ? 'secondary' : 'primary'"
              x-large
            >
              Crear registro
            </v-btn>
          </v-card-title>
          <v-card-text class="px-0 py-0">
            <!-- Mostrar departamentos -->
            <div
              class="d-flex align-center mt-4 mb-1"
              style="gap: 16px; height: 32px"
            >
              <template v-if="departamentos.isLoading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="18"
                  :width="3"
                />
                <p class="mb-0 font-weight-bold">Cargando departamentos...</p>
              </template>
              <template v-else>
                <div
                  v-for="(departamento, index) in listaDepartamentos"
                  :key="index"
                  class="font-weight-bold"
                >
                  <span
                    :style="`background-color: ${departamento.color};`"
                    style="
                      width: 18px;
                      height: 18px;
                      border-radius: 50%;
                      vertical-align: sub;
                    "
                    class="d-inline-block mr-1"
                  />
                  {{ departamento.nombre }}
                </div>
              </template>
            </div>
            <v-divider />
            <!-- Historial -->
            <div
              v-if="cargaActividadesRecientes.isLoading"
              class="text-center mt-4"
            >
              <v-progress-circular
                indeterminate
                class="mt-2"
                color="primary"
                :size="25"
                :width="3"
              />
              <p class="py-0 my-0 mt-2 font-weight-bold">
                Cargando actividades recientes...
              </p>
            </div>
            <div
              v-if="bitacoras.length > 0"
              style="max-height: 730px; overflow: auto"
            >
              <app-time-line :items="bitacoras" :showActions="false">
                <template v-slot:item="{ item }">
                  <span class="text-break" style="line-height: 24px">
                    <b>
                      {{ item.fecha }}
                      <span v-if="item.creadoPorFormulario"
                        >({{ item.creadoPor }})</span
                      >
                      -
                      {{ item.departamento.nombre }}
                      :
                    </b>
                    {{ item.descripcion }}
                    <v-btn
                      v-if="item.adjunto"
                      icon
                      style="transform: translateY(-2px)"
                      @click.stop="visualizarPdf(item.id)"
                    >
                      <v-icon color="secondary">mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="primary"
                      style="transform: translateY(-2px)"
                      @click.stop="abrirModalDetalleBitacora(item)"
                    >
                      <v-icon color="secondary">mdi-eye</v-icon>
                    </v-btn>
                  </span>
                </template>
              </app-time-line>
              <div
                v-intersect="debouncedIntersectionHandler"
                class="text-center"
              ></div>
            </div>
            <EmptyComponent
              v-if="bitacoras.length === 0 && !historial.isLoading"
              class="mt-4"
              text="No hay registros"
              colored
              empty
            />
            <div v-if="historial.isLoading" class="text-center mt-4">
              <v-progress-circular
                indeterminate
                class="mt-2"
                color="primary"
                :size="25"
                :width="3"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ModalCreacionRegistro
      :isOpen="modalCreacionRegistrosAbierta"
      :idSeguimiento="idSeguimientoProceso"
      :tipos="catalogoTipoRegistros"
      @on-visibility-change="manejarVisibilidadModalCreacionRegistros"
      @on-nueva-actividad="refrescarActividadesRecientes"
      @on-ocultamiento-seguimiento="manejarOcultamientoSeguimiento"
    />
    <PdfModal
      :isOpen="modalVisualizacionPdfbierta"
      :source-loadable="visualizacionPdf"
      @on-visibility-change="manejarVisibilidadModalVisualizacionPdf"
      filename="archivo-seguimiento"
    />
    <ModalVerBitacora
      :is-open="modalDetalleBitacoraAbierta"
      :bitacora="bitacoraActiva"
      @on-visibility-change="manejarVisibilidadModalDetalleBitacora"
    />
    <ModalAsignarSeguimiento
      v-if="esJefeDeSeguimiento"
      :is-open="modalAsignacionSeguimientoAbierta"
      :id-proceso="procesoCompra?.id"
      :asignacion-actual="asignacionActual"
      @on-visibility-change="cerrarModalAsignacionSeguimiento"
      @on-cambio-asignacion="manejarCambioAsignacion"
      @on-ocultamiento-seguimiento="manejarOcultamientoSeguimiento"
    />
    <ConfirmationModalComponent
      :is-open="modalConfirmacionVisibilidadIndividualAbierta"
      :description="mensajeConfirmacionVisibilidadIndividual"
      :is-loading="cambioVisibilidadIndividual.isLoading"
      @cancel="cancelarCambioVisibilidadIndividual"
      @confirm="cambiarVisibilidadIndividual"
    />
  </v-container>
</template>
<script>
import {
  createLoadable,
  createPageable,
  toggleLoadable,
  togglePageable,
  setLoadableResponse,
  setPageableResponse,
  isResponseSuccesful,
  shouldFetch,
} from "@/utils/loadable";
import {
  ConfirmationModalComponent,
  EmptyComponent,
  NavButton,
  RetryDataLoading,
} from "@/components/utils";
import ModalAsignarSeguimiento from "./components/ModalAsignarSeguimiento.vue";
import ModalCreacionRegistro from "./components/ModalCreacionRegistro.vue";
import ModalVerBitacora from "./components/ModalVerBitacora.vue";
import CambioEstado from "./components/CambioEstado.vue";
import PdfModal from "@/components/PdfModal.vue";
import { mapActions, mapState } from "vuex";
import { joinList } from "@/utils/text";
import { debounce } from "lodash";
import moment from "moment";

const codigosSeguimientoOculto = [
  "SEGUIMIENTO_OCULTO_PARA_USUARIO",
  "SEGUIMIENTO_NO_DISPONIBLE",
];

export default {
  components: {
    NavButton,
    EmptyComponent,
    ModalCreacionRegistro,
    PdfModal,
    ModalVerBitacora,
    CambioEstado,
    ModalAsignarSeguimiento,
    RetryDataLoading,
    ConfirmationModalComponent,
  },
  data: () => ({
    idSeguimientoProceso: null,
    // Data
    catalogoTipoRegistros: createLoadable([]),
    asignacion: createLoadable(null),
    historial: createPageable([], 10),
    cargaActividadesRecientes: createLoadable(null),
    filtrosHistorial: {
      pagina: 1,
      registrosPorPagina: 10,
    },
    // Creacion de registros
    modalCreacionRegistrosAbierta: false,
    // Visualizacion PDF
    modalVisualizacionPdfbierta: false,
    visualizacionPdf: createLoadable(null),
    // Detalle de bitacora
    modalDetalleBitacoraAbierta: false,
    bitacoraActiva: null,
    // Seguimiento
    modalAsignacionSeguimientoAbierta: false,
    asignacionActual: {},
    // Visibilidad individual
    visibleParaResponsable: false,
    visibleParaAsignado: false,
    tecnicoTarget: "",
    modalConfirmacionVisibilidadIndividualAbierta: false,
    cambioVisibilidadIndividual: createLoadable(null),
  }),
  computed: {
    ...mapState("seguimientoProcesos", ["departamento", "departamentos"]),
        listaDepartamentos() {
            return this.departamentos.data.filter((departamento) => departamento.codigo !== "OBSERVATORIO");
        },
    esJefeDeSeguimiento() {
      return this.haveRoles([
        "ROLE_JEFE_SYC_SEGUIMIENTO_PROCESOS_VIEW",
        "ROLE_JEFE_FISCALIZACION_SEGUIMIENTO_PROCESOS_VIEW",
      ]);
    },
    bitacoras() {
      return this.historial.data.map((bitacora, index) => {
        const {
          id,
          adjunto,
          descripcion,
          created_at,
          Usuario,
          CtlTipoBitacoraSeguimientoProceso,
          base_legal,
          MntSeguimientoProceso,
        } = bitacora;

        const color =
          MntSeguimientoProceso.CtlDepartamentoSeguimientoProceso.color;

        return {
          id,
          creadoPor: this.formatearPersona(Usuario),
          descripcion,
          fecha: this.formatearFechaHora(created_at),
          color,
          creadoPorFormulario:
            CtlTipoBitacoraSeguimientoProceso.creado_por_formulario,
          adjunto,
          baseLegal: base_legal,
          tipo: CtlTipoBitacoraSeguimientoProceso,
          departamento: MntSeguimientoProceso.CtlDepartamentoSeguimientoProceso,
        };
      });
    },
    estadoSeguimiento() {
      return this.asignacion.data?.es_sp.id;
    },
    seguimientoRevisado() {
      return this.estadoSeguimiento === 4;
    },
    procesoCompra() {
      return this.asignacion.data?.ProcesoCompra;
    },
    informacionAsignacion() {
      return this.asignacion.data;
    },
    mensajeConfirmacionVisibilidadIndividual() {
      if (!this.asignacion.data) return "";

      const actualmenteVisible =
        this.tecnicoTarget === "responsable"
          ? this.asignacion.data.visible_para_responsable
          : this.asignacion.data.visible_para_asignado;
      return `¿Desea ${
        actualmenteVisible ? "ocultar" : "habilitar"
      } el seguimiento para el técnico ${
        this.tecnicoTarget === "responsable" ? "responsable" : "asignado"
      }?`;
    },
    seguimientoOculto() {
      if (!this.asignacion.error) return false;

      return codigosSeguimientoOculto.includes(this.asignacion.error.code);
    },
  },
  methods: {
    ...mapActions("seguimientoProcesos", [
      "cargarCatalogoDeEstados",
      "cargarDepartamentoUsuarioLogueado",
      "cargarCatalogoDepartamentos",
    ]),
    async cargarAsignacion() {
      toggleLoadable(this.asignacion);
      const { data } = await this.services.SeguimientoProcesos.buscarAsignacion(
        this.idSeguimientoProceso
      );
      setLoadableResponse(this.asignacion, data);

      if (isResponseSuccesful(data)) {
        if (shouldFetch(this.historial)) this.cargarHistorial();

        return;
      }

      if (codigosSeguimientoOculto.includes(data.error.code)) {
        this.limpiarHistorial();
      }
    },
    async cargarCatalogoTipoRegistros() {
      toggleLoadable(this.catalogoTipoRegistros);
      const { data } =
        await this.services.SeguimientoProcesos.cargarCatalogoTipoRegistros({
          soloPorFormulario: true,
        });
      setLoadableResponse(this.catalogoTipoRegistros, data);
    },
    // Data historial
    async cargarHistorial() {
      const filtros = {
        pagination: true,
        page: this.filtrosHistorial.pagina,
        per_page: this.filtrosHistorial.registrosPorPagina,
      };

      togglePageable(this.historial);
      const { data, headers } =
        await this.services.SeguimientoProcesos.cargarHistorial(
          this.idSeguimientoProceso,
          filtros
        );
      setPageableResponse(this.historial, data, headers, {
        dontShowErrorIfCode: codigosSeguimientoOculto,
      });
    },
    async cargarSiguientesActividades() {
      if (this.historial.isLoading) return;
      const { page, per_page, total_rows } = this.historial.pagination;

      const registrosCargados = Math.ceil(page * per_page);

      if (!(registrosCargados < total_rows)) return;

      const actividadesCargadasLength = this.historial.data.length;
      const ultimaActividadCargada =
        this.historial.data[actividadesCargadasLength - 1];

      if (!ultimaActividadCargada) return;

      const filtros = {
        pagination: true,
        page: 1,
        per_page: this.filtrosHistorial.registrosPorPagina,
        antes_de: ultimaActividadCargada.id,
      };

      togglePageable(this.historial);
      const { data, headers } =
        await this.services.SeguimientoProcesos.cargarHistorial(
          this.idSeguimientoProceso,
          filtros
        );
      setPageableResponse(this.historial, data, headers, {
        skipOnSuccess: true,
        dontShowErrorIfCode: codigosSeguimientoOculto,
      });

      if (isResponseSuccesful(data)) {
        const historialActual = this.historial.data;
        const historialActualizado = historialActual.concat(data.data);
        this.historial.data = historialActualizado;
        return;
      }

      if (codigosSeguimientoOculto.includes(data.error.code)) {
        this.manejarOcultamientoSeguimiento();
      }
    },
    async refrescarActividadesRecientes() {
      const primeraActividadCargada = this.historial.data[0];

      if (!primeraActividadCargada) return;

      const filtros = {
        pagination: false,
        despues_de: primeraActividadCargada.id,
      };

      toggleLoadable(this.cargaActividadesRecientes);
      const { data } = await this.services.SeguimientoProcesos.cargarHistorial(
        this.idSeguimientoProceso,
        filtros
      );
      setLoadableResponse(this.cargaActividadesRecientes, data, {
        skipOnSuccess: true,
        dontShowErrorIfCode: codigosSeguimientoOculto,
      });

      if (isResponseSuccesful(data)) {
        const historialActual = this.historial.data;
        const historialActualizado = data.data.concat(historialActual);
        this.historial.data = historialActualizado;
        return;
      }

      if (codigosSeguimientoOculto.includes(data.error.code)) {
        this.manejarOcultamientoSeguimiento();
      }
    },
    async visualizarPdf(idBitacora) {
      this.modalVisualizacionPdfbierta = true;
      toggleLoadable(this.visualizacionPdf);
      const { data } = await this.services.SeguimientoProcesos.visualizacionPdf(
        idBitacora
      );
      setLoadableResponse(this.visualizacionPdf, data, { isFile: true });
    },
    // UI
    formatearFecha(fecha) {
      return fecha ? moment(fecha, "YYYY-MM-DD").format("DD-MM-YYYY") : "-";
    },
    formatearFechaHora(fecha) {
      return fecha ? moment(fecha).format("DD-MM-YYYY HH:mm:ss") : "-";
    },
    formatearPersona(persona) {
      if (!persona) return "-";

      const {
        primer_nombre,
        segundo_nombre,
        tercer_nombre,
        primer_apellido,
        segundo_apellido,
        apellido_casada,
      } = persona.Empleado.Persona;
      const componentes = [
        primer_nombre,
        segundo_nombre,
        tercer_nombre,
        primer_apellido,
        segundo_apellido,
        apellido_casada,
      ];

      return joinList(componentes, " ");
    },
    obtenerNombreEtapa(proceso) {
      return `${
        proceso.EstadoProceso.id === 1
          ? proceso.SeguimientoProceso?.nombre
          : proceso.EstadoProceso.nombre
      }`;
    },
    obtenerColorEtapa(proceso) {
      const coloresMap = {
        5: "error",
        6: "amber lighten-3",
        7: "purple lighten-2",
        8: "info",
      };

      return proceso.EstadoProceso.id === 1
        ? proceso.SeguimientoProceso?.color
        : coloresMap[proceso.EstadoProceso.id];
    },
    abrirModalCreacionRegistro() {
      this.modalCreacionRegistrosAbierta = true;
    },
    manejarVisibilidadModalCreacionRegistros(visible) {
      this.modalCreacionRegistrosAbierta = visible;
    },
    handleIntersection(entries, observer, isIntersecting) {
      if (!isIntersecting) return;

      this.cargarSiguientesActividades();
    },
    manejarCambioEstado() {
      this.cargarAsignacion();
      this.refrescarActividadesRecientes();
    },
    manejarCambioAsignacion() {
      this.cargarAsignacion();
      this.refrescarActividadesRecientes();
    },
    manejarVisibilidadModalVisualizacionPdf(visible) {
      this.modalVisualizacionPdfbierta = visible;
    },
    abrirModalDetalleBitacora(bitacora) {
      this.bitacoraActiva = bitacora;
      this.modalDetalleBitacoraAbierta = true;
    },
    manejarVisibilidadModalDetalleBitacora(visible) {
      this.modalDetalleBitacoraAbierta = visible;
    },
    cerrarModalAsignacionSeguimiento(visible) {
      this.modalAsignacionSeguimientoAbierta = visible;
    },
    abrirModalAsignacion() {
      this.modalAsignacionSeguimientoAbierta = true;

      const meta = {
        departamento: this.asignacion.data.CtlDepartamentoSeguimientoProceso.id,
        responsable: this.asignacion.data.us_res?.id,
        asignado: this.asignacion.data.us_as?.id,
      };

      this.asignacionActual = meta;
    },
    reintentarCargaAsignacion() {
      this.cargarAsignacion();
      this.cargarHistorial();
    },
    limpiarHistorial() {
      this.filtrosHistorial.pagina = 1;
      this.historial = createPageable([], 10);
      this.cargaActividadesRecientes = createLoadable(null);
    },
    manejarOcultamientoSeguimiento() {
      this.limpiarHistorial();
      this.cargarAsignacion();
    },
    // Manejo cambio visibilidad individual
    confirmarCambioVisibilidadIndividual(target) {
      this.modalConfirmacionVisibilidadIndividualAbierta = true;
      this.tecnicoTarget = target;
    },
    cancelarCambioVisibilidadIndividual() {
      this.modalConfirmacionVisibilidadIndividualAbierta = false;

      if (this.tecnicoTarget === "responsable") {
        this.visibleParaResponsable =
          this.asignacion.data.visible_para_responsable;
      } else {
        this.visibleParaAsignado = this.asignacion.data.visible_para_asignado;
      }
    },
    async cambiarVisibilidadIndividual() {
      const visibilidadActual =
        this.tecnicoTarget === "responsable"
          ? this.asignacion.data.visible_para_responsable
          : this.asignacion.data.visible_para_asignado;
      const payload = {
        mostrar: !visibilidadActual,
        tecnico_target: this.tecnicoTarget,
      };

      toggleLoadable(this.cambioVisibilidadIndividual);
      const { data } =
        await this.services.SeguimientoProcesos.cambiarVisibilidadIndividualmente(
          this.idSeguimientoProceso,
          payload
        );
      this.modalConfirmacionVisibilidadIndividualAbierta = false;
      setLoadableResponse(this.cambioVisibilidadIndividual, data, {
        showAlertOnSuccess: true,
        skipOnSuccess: true,
        dontShowErrorIfCode: codigosSeguimientoOculto,
      });

      if (isResponseSuccesful(data)) {
        this.cargarAsignacion();

        return;
      }

      if (codigosSeguimientoOculto.includes(data.error.code)) {
        this.manejarOcultamientoSeguimiento();
      }
    },
  },
  created() {
    this.idSeguimientoProceso = this.$route.params.id_seguimiento_proceso;
    this.cargarAsignacion();
    this.cargarHistorial();
    this.cargarCatalogoDeEstados();
    this.cargarCatalogoTipoRegistros();
    this.cargarCatalogoDepartamentos();

    if (shouldFetch(this.departamento))
      this.cargarDepartamentoUsuarioLogueado();

    this.debouncedIntersectionHandler = debounce(this.handleIntersection, 500);
  },
  watch: {
    informacionAsignacion: {
      handler(value) {
        if (!value) {
          this.visibleParaAsignado = false;
          this.visibleParaResponsable = false;
          return;
        }

        this.visibleParaAsignado = Boolean(value.visible_para_asignado);
        this.visibleParaResponsable = Boolean(value.visible_para_responsable);
      },
      deep: true,
    },
  },
};
</script>
