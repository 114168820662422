import { render, staticRenderFns } from "./detalleSeguimientoProceso.vue?vue&type=template&id=3b8e6c17&"
import script from "./detalleSeguimientoProceso.vue?vue&type=script&lang=js&"
export * from "./detalleSeguimientoProceso.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports